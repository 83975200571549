<template>
  <div>
    <div class="d-flex content-wrapper mb-3">
      <div class="d-flex">
        <router-link
          class="btn btn-primary mr-2"
          :to="{name: 'add-tn'}"
        >
          Add TN
        </router-link>
        <router-link
          class="btn btn-primary"
          :to="{name: 'import-tn'}"
        >
          Import TN
        </router-link>
      </div>
      <a-auto-complete
        class="ml-auto select-content"
        style="width: 300px"
        placeholder="Search TN"
        @search="handleSearch"
      >
        <a-input>
          <template #suffix>
            <a
              class="search-btn"
              @click="searchItems"
            >
              <i
                class="fa fa-search"
                aria-hidden="true"
              />
            </a>
          </template>
        </a-input>
      </a-auto-complete>
    </div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th @click="sort('tn')">
              TN
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'tn' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('ocn')">
              OCN
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'ocn' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('lata')">
              LATA
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'lata' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('state')">
              State
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'state' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('rate_center')">
              Rate Center
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'rate_center' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('carrier')">
              Carrier
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'carrier' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.tn }}</td>
            <td>{{ item.ocn }}</td>
            <td>{{ item.lata }}</td>
            <td>{{ item.state }}</td>
            <td>{{ item.rate_center }}</td>
            <td>{{ item.carrier }}</td>
            <td
              class="d-flex"
              width="120px"
            >
              <a-tooltip
                placement="top"
                class="pr-0"
              >
                <template #title>
                  Delete
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="deleteItem(item)"
                >
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        show-less-items
        class="pagination-right"
        :default-current="1"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
    import axios from '@/axios'
    import {Modal} from 'ant-design-vue'

    export default {
        data() {
            return {
                step: 10,
                skip: 0,
                total: null,
                items: null,
                dataSource: ['Burns Bay Road', 'Downing Street', 'Wall Street'],
                currentSort: 'created_on',
                currentSortDir: 'DESC',
                tnNumber: ''
            }
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            sort(s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'ASC' ? 'DESC' : 'ASC';
                }
                this.currentSort = s;
                this.loadItems()
            },
            loadItems() {
                axios
                    .get(`/authentication/tn?order_dir=${this.currentSortDir}&order_by=${this.currentSort}&take=10&skip=${this.skip}`)
                    .then((res) => {
                        this.items = res.data.data
                        this.total = res.data.count > this.step ? res.data.count : null
                    }).catch(() => {})
            },
            searchItems() {
                axios
                    .get(`/authentication/tn?tnNumber=${this.tnNumber}&order_dir=DESC&order_by=created_on&take=10&skip=0`)
                    .then((res) => {
                        this.items = res.data.data
                      console.log(this.items,1111)
                        this.total = res.data.count > this.step ? res.data.count : null
                        this.tnNumber = ''
                    }).catch(() => {})
            },
            deleteItem(item) {
                const vm = this
                Modal.confirm({
                    title: 'Do you Want to delete this item?',
                    // content: (h) => <div style="color:red;">Some descriptions</div>,
                    onOk() {
                        vm.deleteReq(item)
                    },
                    onCancel() {
                        console.log('Cancel')
                    },
                    class: 'test',
                })
            },
            deleteReq(item) {
                axios
                    .delete(`/authentication/tn/${item.tn}`)
                    .then((res) => {
                        this.$store.dispatch('deleted', item.tn)
                        this.loadItems()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            handleSearch(e) {
                this.tnNumber = e
            },
            pagination(int) {
                this.skip = 0
                this.skip += this.step * int - this.step
                this.loadItems()
            },
        },
    }
</script>

<style scoped>
    .content-wrapper {
      flex-wrap: wrap;
    }
    th {
        cursor: pointer;
    }

    .asc-desc-icon {
        margin-left: .3rem;
        color: #d0dcf1;
    }

    .pagination-right {
        text-align: right;
        margin-right: 210px;
    }
    @media (max-width: 615px) {
      .select-content {
        width: 100%;
        margin-top: 10px;
      }
    }
</style>
